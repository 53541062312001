import { useContext, useState } from "react";

import mergeWith from "lodash/mergeWith";
import pick from "lodash/pick";

import { Ticket } from "~graphql/sdk";
import {
  TicketNamingActionTypes,
  TicketNamingContext,
} from "~context/ticketNamingContext";

// TODO - the Ticket type used here should be reduced to bare minimum
// required fields so it can be used with the various ticket models we receive from the API.
export const useTicketNaming = () => {
  const [showTransferTicketModal, setShowTransferTicketModal] = useState(false);
  const { dispatch, showModal, ticket, initialTicket, ticketType } = useContext(
    TicketNamingContext
  );

  const closeModal = () =>
    dispatch({ type: TicketNamingActionTypes.CLOSE_MODAL });
  const openModal = () =>
    dispatch({ type: TicketNamingActionTypes.OPEN_MODAL });
  const initTicket = (ticket: Ticket) =>
    dispatch({
      type: TicketNamingActionTypes.INIT_TICKET,
      payload: ticket,
    });
  const setTicket = (ticket: Ticket) =>
    dispatch({
      type: TicketNamingActionTypes.SET_TICKET,
      payload: ticket,
    });
  const unsetTicket = (ticket: Ticket) =>
    dispatch({ type: TicketNamingActionTypes.UNSET_TICKET, payload: ticket });

  const getUserData = (user, buyerInformation, ticket) =>
    pick(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      mergeWith({}, user, buyerInformation, ticket, (o, s) => (!s ? o : s)),
      [
        "firstName",
        "lastName",
        "postalCode",
        "birthDate",
        "gender",
        "companyName",
        "email",
        "phoneNumber",
      ]
    );

  return {
    showModal,
    ticket,
    initialTicket,
    ticketType,
    helpers: {
      showTransferTicketModal,
      setShowTransferTicketModal,
      closeModal,
      openModal,
      initTicket,
      setTicket,
      unsetTicket,
      getUserData,
    },
  };
};
