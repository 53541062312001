import { useContext } from "react";
import useSWR from "swr";
import { Menu, Seo, Status } from "~components";
import { EmptyState } from "~components/events/events.EmptyState";
import { BaseContext } from "~context";
import { Detail } from "~features/events/components/EventDetails/EventDetails";
import { sdk } from "~lib/graphQL";
import { Event } from "~graphql/sdk";
import { MobileEventHeaderSinglePage } from "~components/common/Menu/menu.EventHeader";
import { OptionalSeekaProvider } from "~lib/seeka";

const SingleEventPage = () => {
  const { organization } = useContext(BaseContext);

  const { data, error } = useSWR(
    organization?.id ? ["singleEvent", organization?.id] : null,
    async (_: string, orgId: string) => sdk({ orgId }).singleEvent()
  );

  return (
    <>
      <Seo
        title={data?.currentOrganization?.singleEvent?.title}
        description={`Buy tickets to ${data?.currentOrganization?.singleEvent?.title}`}
      />
      <Menu>
        {data?.currentOrganization?.singleEvent && (
          <MobileEventHeaderSinglePage
            event={data?.currentOrganization?.singleEvent as Event}
          />
        )}
      </Menu>
      <Status loading={!data && !error} error={error}>
        <OptionalSeekaProvider
          seekaConfig={data?.currentOrganization?.singleEvent?.seekaConfig}
        >
          {data?.currentOrganization?.singleEvent ? (
            <Detail
              // @ts-expect-error type mismatch
              event={data?.currentOrganization?.singleEvent}
            />
          ) : (
            <EmptyState />
          )}
        </OptionalSeekaProvider>
      </Status>
    </>
  );
};

export default SingleEventPage;
