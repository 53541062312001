interface Require extends NodeRequire {
  context: (
    directory: string,
    useSubdirectories: boolean,
    regExp: RegExp,
    mode?: string
  ) => NodeRequire;
}

// https://webpack.js.org/guides/dependency-management/#requirecontext
export default (require as Require).context(
  '!@svgr/webpack?{"icon":"true","svgo":"true"}!./library',
  false,
  /\.svg$/
);
