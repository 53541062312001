import { LandingPage } from "~graphql/sdk";

import { useEventsService } from "~features/events/hooks/useEventsService";
import Tours from "~form/tours/toursOverview";
import SingleEventPage from "./event";
import Events from "./events";
import Overall from "./overall";
import { Status } from "~components";
import { useOrganization } from "~hooks";
import CalendarHomePage from "./calendar";
import { OptionalSeekaProvider } from "~lib/seeka";

const Home = () => {
  const { organization, hasFeature } = useOrganization();

  const { events, isLoading, error } = useEventsService();

  let PageComponent = Events;
  let orgLevelSeekaProvider = true;

  if (hasFeature("eventCalendarHomepage")) {
    PageComponent = CalendarHomePage;
  } else if (
    organization.landingPage === LandingPage.SingleEvent &&
    events?.length == 1
  ) {
    PageComponent = SingleEventPage;

    // The single event landing page uses its own provider so there is no need to enable one here.
    orgLevelSeekaProvider = false;
  } else if (organization.landingPage === LandingPage.SingleEvent) {
    PageComponent = Overall;
  } else if (organization.landingPage === LandingPage.Tours) {
    PageComponent = Tours;
  }

  return (
    <Status loading={isLoading} error={error}>
      {orgLevelSeekaProvider ? (
        <OptionalSeekaProvider seekaConfig={organization?.seekaConfig}>
          <PageComponent />
        </OptionalSeekaProvider>
      ) : (
        <PageComponent />
      )}
    </Status>
  );
};

export default Home;
