import { ServerResponse } from "http";
import { NextPageContext } from "next";
import { AppPageProps } from "~pages/_app";

export const HighDemandRedirect = (
  response: NextPageContext["res"] | ServerResponse,
  redirectUrl: string
): { props: AppPageProps["props"] } => {
  if (response && !redirectUrl.includes("high-demand")) {
    response.writeHead(302, {
      Location: `/high-demand?p=${encodeURIComponent(redirectUrl)}`,
    });
    response.end();
  }
  return {
    props: {} as AppPageProps["props"],
  };
};
