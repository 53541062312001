import { useTheme, DefaultTheme } from "styled-components";
import { ThemeValue } from "styled-system";
import { getLightOrDarkColor } from "~lib";

type ThemeColour = ThemeValue<"colors", DefaultTheme>;

/**
 * Returns the text colour that should be used based on the background colour.
 * Default background colour is P300.
 *
 * @param backgroundColour - The background colour to base the text colour on.
 * @returns - The text colour that should be used to contrast the background colour.
 */
export const useTextColour = (backgroundColour?: ThemeColour) => {
  const theme = useTheme();
  const dark = theme.colors.N800;
  const light = theme.colors.white;

  const textColour = getLightOrDarkColor({
    background: backgroundColour ?? theme.colors.P300,
    dark,
    light,
  }) as ThemeColour;

  return textColour;
};
