import React, { FC, forwardRef } from "react";
import { Box, SystemProps } from "flicket-ui";
import styled from "styled-components";

import reqIcon from "./req";

const StyledIcon = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  svg {
    vertical-align: middle;
  }
`;

export type IconName =
  | "accessibility_purple"
  | "accessibility"
  | "account"
  | "admin-panel"
  | "afterpay"
  | "afterpay-icon"
  | "alcohol-free"
  | "arrow-dropdown"
  | "assignment"
  | "backspace"
  | "calendar"
  | "calendar-today"
  | "check-circle"
  | "check-circle-thin"
  | "check"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "close"
  | "compare"
  | "cursor"
  | "date-range"
  | "dollar"
  | "double-chevron-left"
  | "double-chevron-right"
  | "email"
  | "error"
  | "event"
  | "facebook"
  | "facebook-event"
  | "fb-like"
  | "filter"
  | "google"
  | "help"
  | "info"
  | "instagram"
  | "laybuy"
  | "laybuy_sunglasses"
  | "laybuy_lb_heart"
  | "laybuy_cursor"
  | "laybuy_calendar"
  | "logout"
  | "map"
  | "membership"
  | "menu"
  | "messenger"
  | "minus"
  | "more"
  | "my-account"
  | "my-tickets"
  | "print"
  | "plus"
  | "r18"
  | "radio-checked"
  | "radio"
  | "remove-circle"
  | "search"
  | "share"
  | "shopping-cart"
  | "sunglasses"
  | "support"
  | "tick"
  | "ticket"
  | "tiktok"
  | "twitter"
  | "twitter-email"
  | "user-account"
  | "zoom-in"
  | "person-arms-spread"
  | "map-pin"
  | "calendar-blank";

export interface IconProps
  extends SystemProps,
    Omit<Omit<React.HTMLAttributes<HTMLDivElement>, "color">, "css"> {
  icon: IconName | JSX.Element;
  as?: string;
  href?: string;
  className?: string;
  onClick?: any;
  type?: "button" | "submit";
}

export const Icon: FC<IconProps> = forwardRef(
  ({ icon, color, ...props }, ref) => {
    function getAs(): any {
      switch (true) {
        case props.as !== undefined:
          return props.as;
        case props.href !== undefined:
          return "a";
        case props.onClick !== undefined:
          return "button";
        default:
          return "div";
      }
    }

    function getIcon(): JSX.Element {
      if (isIconName(icon)) {
        const IconSvg = reqIcon(`./${icon}.svg`).default as React.FC<
          React.SVGProps<SVGSVGElement>
        >;
        return <IconSvg />;
      }

      return icon;
    }
    return (
      <StyledIcon
        className="icon"
        ref={ref}
        aria-hidden
        as={getAs()}
        color={color}
        {...props}
      >
        {getIcon()}
      </StyledIcon>
    );
  }
);

function isIconName(icon: IconProps["icon"]): icon is IconName {
  return typeof icon === "string";
}

Icon.displayName = "Icon";

export default Icon;
