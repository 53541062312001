import { sdk } from "~lib";
import { handlePromise } from "~lib/helpers";
import { userAgent } from "~lib/helpers/constants";
import { NextPageContext } from "next";
import {
  CurrentOrganizationQuery,
  EventQuery,
  OrderByDirection,
} from "~graphql/sdk";
import { today } from "./helpers/dates";
import { State } from "./helpers/handlePromise";

export const getServerSideOrganization = async (
  req: NextPageContext["req"]
) => {
  const MAX_REQUEST_DURATION_MS = 45 * 1000; // 45 seconds

  const host = req?.headers
    ? `https://${req.headers["x-forwarded-host"] || req.headers.host}`
    : "";

  const result = Promise.race<State<CurrentOrganizationQuery>>([
    new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error("CURRENT_ORGANIZATION_TIMEOUT")),
        MAX_REQUEST_DURATION_MS
      )
    ),
    handlePromise(async () =>
      sdk({
        host,
        key: "user-agent",
        value: userAgent,
        isServer: true,
      }).currentOrganization()
    ),
  ]);

  return result;
};

export const getServerSideI18n = async (orgId: string) => {
  return handlePromise(async () =>
    sdk({
      orgId,
      key: "user-agent",
      value: userAgent,
      isServer: true,
    }).getI18n()
  );
};

export const getServerSideSeoMetadata = async (
  orgId: string,
  eventId?: string,
  membershipId?: string
) =>
  handlePromise(async () =>
    sdk({ orgId, isServer: true }).seoMetadata({ eventId, membershipId })
  );

export const getServerSideEvent = async ({
  orgId,
  eventId,
}: {
  orgId: string;
  eventId: string;
}): Promise<State<EventQuery>> =>
  handlePromise(async () =>
    sdk({ orgId, isServer: true }).event({ id: eventId })
  );

export const getServerSideEvents = async (
  orgId: string,
  search?: string,
  seasonId?: string
) => {
  const where = {
    startDate: today,
    ...(search && { q: search }),
    seasonId,
  };

  return handlePromise(async () =>
    sdk({ orgId, isServer: true })
      .events({
        where,
        orderBy: {
          startDate: OrderByDirection.Asc,
        },
      })
      .then((res) =>
        res.events?.edges
          ?.map(({ node }) => node)
          .filter((event) => !event.hiddenFromPublic)
      )
  );
};
