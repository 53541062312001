/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  useMemo,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useState,
} from "react";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { SystemProps } from "flicket-ui";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import flatten from "lodash/flatten";
import startCase from "lodash/startCase";
import { useRouter } from "next/router";
import styled from "styled-components";

import { seatsIOPublicKey } from "~config";
import {
  BaseContext,
  ReservationContext,
  ReservationActionTypes,
} from "~context";
import { sdk } from "~lib";
import { SeatSelectTypes } from "~components/reservation/constants";
import { HoldBestAvailableSeatsInput } from "~graphql/sdk";
import {
  handlePromise,
  getError,
  showToast,
  getCategoryDataFromLabel,
} from "~lib/helpers";
import { useAccount } from "~hooks/useAccount";
import { groupCategoriesIntoZones } from "~features/seated-reservation/helpers/getChartZones";
import { Release } from "~features/seated-reservation/types";

const StyledSeatsWrapper = styled("div")<SystemProps>`
  height: 100%;
  max-height: 293px;

  max-width: 100%;

  @media (min-width: ${(p) => p.theme.breakpoints.sm}) {
    max-height: 480px;
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    height: 100%;
    max-height: 100%;
  }
`;

const selectSeats = async (
  orgId: string,
  releaseId: string,
  clearSelection: () => void,
  input: HoldBestAvailableSeatsInput
) => {
  clearSelection();

  const { data, error } = await handlePromise(async () =>
    sdk({ orgId }).holdBestAvailableSeats({ id: releaseId, input })
  );

  if (error) {
    showToast(getError(error, "graphQL"), "error");
    return false;
  }

  return data;
};

interface useChartProps {
  seatsEventKey?: string;
  seatsEventKeys?: string[];
  amount;
  changeTickets;
  currentSeats;
  currentSection;
  currentZone;
  holdToken: string;
  setHoldToken: (newToken: string) => void;
  onSelectCategoryLabel;
  selectBestOnChange?: boolean;
  selectableZones: any[];
  type: "event" | "membership" | "release";
  seatSelectType: SeatSelectTypes;
  tooltipData?: any;
  tooltipDataLoading?: boolean;
  validSeatSelection?: boolean;
  setValidSelection: (value: boolean) => void;
  venue?: any;
  release?: Release;
}

type SeatProps = {
  accessible: boolean;
  capacity: number;
  category: {
    accessible: boolean;
    label: string;
    color: string;
  };
  label: string;
  labels: {
    own: string;
    parent: string;
    section: string;
  };
  objectType: string;
  selectable: boolean;
  selected: boolean;
  status: string;
  id: string;
  uuid: string;
};

const renderObjectColor = (
  { selectable, objectType }: SeatProps,
  defaultColor: string
) => {
  return selectable && objectType === "Seat" ? "#AFCA54" : defaultColor;
};

const renderObjectIcon = (
  { label }: SeatProps,
  defaultIcon: string,
  extraConfig: any
) => {
  if (extraConfig.ownedSeats?.[label]) {
    return "user"; // https://fontawesome.com/v4.7.0/icon/user
  }

  return defaultIcon;
};

const getZoneKeys = (zone) =>
  zone?.sections?.length
    ? flatten(zone?.sections?.map((s) => s?.keys))
    : zone?.keys;

export const useChart = ({
  seatsEventKey,
  seatsEventKeys,
  amount,
  changeTickets,
  currentSection,
  currentZone,
  holdToken,
  setHoldToken,
  selectBestOnChange,
  onSelectCategoryLabel,
  selectableZones,
  seatSelectType,
  currentSeats,
  type,
  tooltipData,
  tooltipDataLoading,
  setValidSelection,
  validSeatSelection,
  venue,
  release,
}: useChartProps) => {
  const chartRef = useRef(null);
  const router = useRouter();
  const { organization } = useContext(BaseContext);
  const { dispatch, seats } = useContext(ReservationContext);
  const [categories, setCategories] = useState(undefined);
  const chartJsUrl = process.env.SEATS_IO_CDN_URL || "";
  const { isAdmin, isPOS } = useAccount();
  const [chartReady, setChartReady] = useState(false);
  const ownedSeats = useMemo(
    () =>
      changeTickets?.reduce((all, current) => {
        all[current?.seatLabel] = true;
        return all;
      }, {}),
    [changeTickets]
  );

  useEffect(() => {
    if (chartRef?.current?.chart) {
      chartRef?.current?.chart?.changeConfig({ extraConfig: { ownedSeats } });
    }
  }, [ownedSeats]);

  const clearSelection = useCallback(() => {
    dispatch({ type: ReservationActionTypes.UNSET_SEATS });
    chartRef?.current?.chart?.clearSelection();
  }, [chartRef?.current?.chart]);

  const resetView = () => chartRef?.current?.chart?.resetView();

  const dispatchSetSeats = async () => {
    let result = [];
    let tries = 0;
    //listSelectedObjects can return an incorrect value due to caching on seats.ios end
    // usually this is nothing
    while (result?.length === 0 && tries < 10) {
      await new Promise((r) => setTimeout(r, 500 * tries));
      tries += 1;
      result = await chartRef.current?.chart?.listSelectedObjects((seats) => {
        const category = seats?.[0]?.category?.label;
        onSelectCategoryLabel(category);

        dispatch({
          type: ReservationActionTypes.SET_SEATS,
          payload: seats.map((seat, idx) => {
            let ticketType = currentSeats?.[idx]?.ticketType;

            if (ticketType) {
              if (currentSection?.sectionTicketTypes) {
                if (
                  !currentSection?.sectionTicketTypes?.find(
                    (t) => t.id === ticketType.value
                  )
                ) {
                  ticketType = undefined;
                }
              }
            }

            const customId =
              seat.objectType === "GeneralAdmissionArea"
                ? `${seat.id}-${idx}`
                : undefined;

            return {
              ...seat,
              ticketType,
              customId,
            };
          }),
        });
      });
    }
    if (tries === 10) {
      window.location.reload();
    }
  };

  const selectBest = useCallback(
    async (categories) => {
      let result;
      if (
        (type === "event" || type === "release") &&
        router?.query?.session !== "continue"
      ) {
        result = await selectSeats(
          organization?.id,
          release.id,
          clearSelection,
          {
            holdToken,
            quantity: amount,
            categories,
          }
        );

        if (!result) {
          dispatch({ type: ReservationActionTypes.SELECT_FAILED });
          return;
        }
      }
      //needed to manage the state of memberships not being able to be put on hold with select best avaliable
      if (result || router?.query?.session === "continue") {
        handleHoldSucceeded();
      }
    },
    [amount, holdToken]
  );
  const setFilter = useCallback(
    (keys: string[]) =>
      chartRef?.current?.chart?.changeConfig({ availableCategories: keys }),
    [chartRef?.current?.current]
  );

  const clearFilter = useCallback(() => {
    const availableCategories = flatten(
      selectableZones?.map((z) => getZoneKeys(z))
    );

    chartRef?.current?.chart?.changeConfig({
      availableCategories: availableCategories?.length
        ? availableCategories
        : [""],
    });
  }, [chartRef?.current?.chart, selectableZones]);

  const setAmount = useCallback(
    (amount: number, seatSelectType: string) =>
      chartRef?.current?.chart?.changeConfig({
        ...(seatSelectType === SeatSelectTypes.FREE_SELECT
          ? { numberOfPlacesToSelect: null, maxSelectedObjects: amount }
          : { numberOfPlacesToSelect: amount, maxSelectedObjects: null }),
      }),
    [chartRef?.current?.chart]
  );

  const setToken = useCallback(
    () => setHoldToken(chartRef?.current?.chart?.holdToken),
    [chartRef?.current?.chart?.holdToken]
  );

  const createNewSession = useCallback(
    () => chartRef?.current?.chart?.startNewSession(setToken, createNewSession),
    [chartRef?.current?.chart]
  );

  // @note this gives users ann indication on why the selecting did not work while free-select
  const handleObjectClick = useCallback(
    (object) => {
      if (object?.selectable) {
        dispatch({
          type: ReservationActionTypes.SEATS_CLICKED,
        });
      }

      if (seatSelectType !== SeatSelectTypes.FREE_SELECT) {
        return;
      }

      if (
        chartRef?.current?.chart?.selectedObjects?.length >= amount &&
        !object.selected
      ) {
        showToast(
          "Exceeded ticket selection amount. Deselect a seat first",
          "error"
        );
        return;
      }
    },
    [chartRef?.current?.chart?.selectedObjects?.length, seatSelectType, amount]
  );

  const handleHoldSucceeded = useCallback(
    debounce(() => {
      dispatchSetSeats();
    }, 200),
    [
      chartRef?.current?.chart,
      dispatch,
      currentSeats,
      currentSection,
      onSelectCategoryLabel,
    ]
  );

  const handleHoldFailed = useCallback(
    (object) => {
      showToast(
        "Selected seats are no longer available. Please select again.",
        "error"
      );
      clearSelection();
    },
    [
      chartRef?.current?.chart,
      dispatch,
      currentSeats,
      currentSection,
      onSelectCategoryLabel,
    ]
  );

  const handleReleaseHoldSucceeded = useCallback(
    (seats) => {
      // This function appears to fire once for each seat that is released
      // and then once at the at the end with an array of the seats released.
      // we are going to use the array version as we want to perform an action when
      // all seats are released.
      if (Array.isArray(seats) && seats[0]?.objectType === "Seat") {
        dispatchSetSeats();
      }
    },
    [
      chartRef?.current?.chart,
      dispatch,
      currentSeats,
      currentSection,
      onSelectCategoryLabel,
    ]
  );

  const handleInvalidSelection = useCallback((object) => {
    if (validSeatSelection) setValidSelection(false);
  }, []);

  const handleValidSelection = useCallback((object) => {
    if (!validSeatSelection) setValidSelection(true);
  }, []);

  const renderTooltipInfo = useCallback(
    (object) => {
      if (object?.objectType === "GeneralAdmissionArea") {
        if (isAdmin) {
          return [
            `[b]Booked:[/b] ${object.numBooked}`,
            `[b]Remaining:[/b] ${object.numFree}`,
          ].join("[br/]");
        }

        if (+object.numFree === 0) {
          return "0 available places";
        }

        if (+object.numFree >= 10) {
          return;
        }

        const placesText = +object.numFree === 1 ? "place" : "places";

        return `${object.numFree} available ${placesText}`;
      }

      if (object?.objectType === "section") {
        if (+object.numberOfSelectableObjects === 0) {
          return "No seats available";
        }

        if (isAdmin) {
          return `[b]Remaining:[/b] ${object.numberOfSelectableObjects}`;
        }

        if (+object.numberOfSelectableObjects >= 10) {
          return;
        }

        const seatsText =
          +object.numberOfSelectableObjects === 1 ? "seat" : "seats";

        return `${object.numberOfSelectableObjects} available ${seatsText}`;
      }

      if (object?.objectType !== "Seat") {
        return;
      }

      const availableCategories = flatten(
        selectableZones?.map((z) => getZoneKeys(z)) || []
      );

      const { zone, section: sectionRaw } = getCategoryDataFromLabel(
        object?.category?.label ?? ""
      );
      const labelParts = object?.label?.split("-");
      const sectionLabel = sectionRaw?.startsWith("Aisle")
        ? "Aisle"
        : "Section";
      const section = sectionRaw?.replace(/^(aisle|section) /i, "") || "-";
      const row = labelParts.length > 1 ? labelParts[1] : "-";
      const seat = labelParts.length > 2 ? labelParts[2] : "-";
      const stand = venue?.zones?.find(({ name }) => name === zone)?.stand;
      const customerDetails = object?.label && tooltipData?.[object.label];
      if (!customerDetails && process.env.NODE_ENV === "development") {
        const { eventId } = router.query;

        console.warn(
          `Couldn't find tooltip data for seat. This may mean there is no active ticket in database`,
          {
            eventId,
            seatLabel: object.label,
          }
        );
      }

      return [
        ...(isAdmin && tooltipDataLoading
          ? ["Loading booking data...[br/]"]
          : [customerDetails]),
        ...(object.status === "free" && !object.selectable
          ? [
              availableCategories?.includes(object?.category?.key)
                ? "Not available in selection"
                : router?.query?.membershipId
                ? "Not available for this membership"
                : "Not available in release",
              "",
            ]
          : []),
        `Seat: ${seat} | Row: ${row} | ${sectionLabel}: ${section}`,
        `${stand ? `Stand: ${stand} | ` : ""}Zone: ${zone}`,
        "",
        `Seat status: ${startCase(object.status)}`,
      ].join("[br/]");
    },
    [selectableZones, isAdmin, tooltipData, tooltipDataLoading]
  );

  useEffect(() => {
    if (chartRef?.current?.chart?.config?.onHoldSucceeded) {
      chartRef.current.chart.config.onHoldSucceeded = handleHoldSucceeded;
    }
  }, [handleHoldSucceeded]);

  useEffect(() => {
    if (chartRef?.current?.chart?.config?.onObjectDeselected) {
      chartRef.current.chart.config.onObjectDeselected = handleReleaseHoldSucceeded;
    }
  }, [handleReleaseHoldSucceeded]);

  useEffect(() => {
    if (chartRef?.current?.chart?.config?.onReleaseHoldSucceeded) {
      chartRef.current.chart.config.onReleaseHoldSucceeded = handleReleaseHoldSucceeded;
    }
  }, [handleReleaseHoldSucceeded]);

  useEffect(() => {
    if (chartRef?.current?.chart?.config?.onObjectClicked) {
      chartRef.current.chart.config.onObjectClicked = handleObjectClick;
    }
  }, [handleObjectClick]);

  useEffect(() => {
    if (chartRef?.current?.chart?.config?.tooltipInfo) {
      chartRef.current.chart.config.tooltipInfo = renderTooltipInfo;
    }
  }, [renderTooltipInfo]);

  useEffect(() => {
    if (chartRef.current?.chart && router?.query?.session !== "continue") {
      setAmount(amount, seatSelectType);

      if (seats && (currentSection || currentZone)) {
        selectBest(
          currentSection
            ? currentSection.categories[0].key
            : getZoneKeys(currentZone)
        );
      }
    }
  }, [amount, seatSelectType]);

  useEffect(() => {
    dispatch({ type: ReservationActionTypes.UNSET_SEATS }); // clear selection on mount
  }, []);

  useEffect(() => {
    if (chartRef.current?.chart && selectBestOnChange) {
      // reset / clear filters if no zone and no section is selected
      if (!currentZone && !currentSection) {
        clearFilter();

        if (currentSeats === null) {
          resetView();
          chartRef.current?.chart?.clearSelection();
        }
      }

      // set filter on zone level
      if (currentZone && !currentSection) {
        setFilter(getZoneKeys(currentZone));

        resetView();

        // select best if the zone has no sections
        if (isEmpty(currentZone.sections)) {
          selectBest(getZoneKeys(currentZone));
        }
      }

      // set filter on section level && select best
      if (currentZone && currentSection) {
        setFilter([currentSection.categories[0].key]);

        if (currentSeats === null) {
          chartRef.current?.chart?.clearSelection();
          resetView();
        } else {
          selectBest(currentSection.categories[0].key);
        }
      }
    }
  }, [currentZone, currentSection, selectableZones]);

  useEffect(() => {
    if (categories) {
      dispatch({
        type: ReservationActionTypes.SET_ZONES,
        payload: groupCategoriesIntoZones(categories),
      });
    }
  }, [categories]);

  const initializeMap = async (chart) => {
    setToken();

    await chartRef.current.chart.listCategories(setCategories);
    setChartReady(true);
  };

  const memoizedMap = useMemo(
    () =>
      !seatsEventKey && !seatsEventKeys?.length ? null : (
        <StyledSeatsWrapper>
          <SeatsioSeatingChart
            ref={chartRef}
            event={seatsEventKey}
            events={seatsEventKeys}
            publicKey={seatsIOPublicKey}
            // objectColor={renderObjectColor}
            objectIcon={renderObjectIcon}
            onChartRendered={initializeMap}
            onHoldFailed={handleHoldFailed}
            onHoldSucceeded={handleHoldSucceeded}
            onObjectDeselected={handleReleaseHoldSucceeded}
            onReleaseHoldSucceeded={handleReleaseHoldSucceeded}
            onObjectClicked={handleObjectClick}
            onHoldTokenExpired={createNewSession}
            onSelectionInvalid={handleInvalidSelection}
            onSelectionValid={handleValidSelection}
            session={
              router?.query?.session === "continue" ? "continue" : "start"
            }
            showFullScreenButton={false}
            chartJsUrl={chartJsUrl}
            extraConfig={{
              ownedSeats,
            }}
            tooltipInfo={renderTooltipInfo}
            selectionValidators={[{ type: "noOrphanSeats" }]}
            multiSelectEnabled={!!(isAdmin ?? isPOS)}
          />
        </StyledSeatsWrapper>
      ),
    [
      chartRef,
      seatsEventKey,
      seatsEventKeys,
      seatsIOPublicKey,
      handleHoldFailed,
      handleHoldSucceeded,
      chartJsUrl,
      setToken,
      createNewSession,
      ownedSeats,
      router?.query?.session,
    ]
  );

  return {
    chart: memoizedMap,
    chartReady,
  };
};
